<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <vx-card title="เลือกรายการทั้งหมด">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/1">
              <label class="vs-input--label">เลือกเว็บในเครือ </label>
              <vs-select class="w-full mt-4" v-model="web">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in webOptions"
                />
              </vs-select>
              <br />
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/2">
              <label class="vs-input--label"
                >เลือกเดือนที่ต้องการดูรายการย้อนหลัง</label
              >
              <!-- <v-select  v-model="month" :options="monthOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
              <vs-select class="w-full mt-4" v-model="month">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in monthOptions"
                />
              </vs-select>
              <br />
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="vs-input--label"
                >เลือกปีที่ต้องการดูรายการย้อนหลัง</label
              >
              <!-- <v-select  v-model="year" :options="yearOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
              <vs-select class="w-full mt-4" v-model="year">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in yearOptions"
                />
              </vs-select>
              <br />
            </div>
            <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <br />
            </div>
          </div>
          <vs-button color="primary" @click="selectWeb(web, month, year)"
            >ค้นหา</vs-button
          >
        </vx-card>
        <br />

        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="PlusCircleIcon"
              icon-right
              :statistic="sum_deposit"
              statisticTitle="ยอดฝาก"
              color="warning"
            />
          </div>

          <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="MinusCircleIcon"
              icon-right
              :statistic="sum_withdraw"
              statisticTitle="ยอดถอน"
              color="danger"
            />
          </div>
          <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sum_profit"
              statisticTitle="กำไร"
              color="success"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumsagame"
              statisticTitle="SA Game"
              color="warning"
            />
          </div>

          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumsexy"
              statisticTitle="Sexy Game"
              color="danger"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumsbobet"
              statisticTitle="SBOBET"
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumkmgame"
              statisticTitle="KINGMAKER GAME "
              color="success"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumxingame"
              statisticTitle="XIN SLOT"
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumjokerslot"
              statisticTitle="JOKER SLOT"
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumambgame"
              statisticTitle="AMB POKER "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumaggame"
              statisticTitle="ASIA GAMING "
              color="success"
            />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumallbetgame"
              statisticTitle="ALLBET GAME"
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumdragongame"
              statisticTitle="DRAGON GAME "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumrchgame"
              statisticTitle="RICH88 GAME "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumhggame"
              statisticTitle="HOTGRAPH GAME "
              color="success"
            />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumpgslot"
              statisticTitle="PG SLOT"
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumgssslot"
              statisticTitle="GSS SLOT "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumsppslot"
              statisticTitle="SimplePlay SLOT "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumppslot"
              statisticTitle="PP SLOT "
              color="success"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="summggame"
              statisticTitle="MG GAME"
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumdggame"
              statisticTitle="DG GAME"
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumevogame"
              statisticTitle="EVO GAME "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumwmgame"
              statisticTitle="WM GAME "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumhdggame"
              statisticTitle="HUAY DRAGON "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumtfggame"
              statisticTitle="TF GAMING "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumspggame"
              statisticTitle="SPADE GAMING "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumdgsgame"
              statisticTitle="DRAGOONSOFT GAMING "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumuggame"
              statisticTitle="UNITED GAMING "
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="sumlalika"
              statisticTitle="LALIKA "
              color="success"
            />
          </div>

        </div>

        <vx-card
          title="ตารางสรุปยอดรายเดือน"
          v-if="table_data_all.length !== 0"
        >
          <h6 class="text-danger mb-4">
            * ยอดอัพเดททุก 4 ชั่วโมง (อัพเดทล่าสุด : {{ lastupdate }})
          </h6>
          <div class="export-table">
            <vs-table stripe :data="table_data_all">
              <template slot="thead">
                <vs-th>วันที่</vs-th>
                <vs-th> Deposit</vs-th>
                <vs-th> Withdraw </vs-th>
                <!-- <vs-th> SA Game</vs-th>
                <vs-th> Sexy Game </vs-th>
                <vs-th> JOKER SLOT </vs-th>
                <vs-th> PG SLOT </vs-th>
                <vs-th> KM GAME </vs-th>
                <vs-th> WM GAME </vs-th>
                <vs-th> AMB POKER </vs-th>
                <vs-th>SBOBET </vs-th>
                <vs-th>GSS SLOT </vs-th>
                <vs-th>SimplePlay SLOT </vs-th>
                <vs-th>EVO GAME </vs-th> -->
                <vs-th> Profit </vs-th>
                <vs-th></vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>{{ data[indextr]["date"] }}</vs-td>
                  <vs-td>{{ currency(data[indextr]["sumdeposit"]) }}</vs-td>
                  <vs-td>{{ currency(data[indextr]["sumwithdraw"]) }}</vs-td>
                  <vs-td
                    class="text-success"
                    v-if="data[indextr]['sumprofit'] > 0"
                  >
                    {{ currency(data[indextr]["sumprofit"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(data[indextr]["sumprofit"])
                  }}</vs-td>
                  <vs-button
                    class="mt-3 mx-1 mb-3"
                    size="medium"
                    color="#00a4e4"
                    @click="winlosegame(indextr)"
                    >win-lose GAME
                  </vs-button>
                </vs-tr>
              </template>
            </vs-table>
            <vs-popup
              classContent="popup-example"
              title="WIN-LOSS GAME "
              :active.sync="popupgame"
            >
              <vs-table stripe :data="table_data_all">
                <vs-tr>
                  <vs-th>วันที่</vs-th>
                  <vs-td>{{ table_data_all[index]["date"] }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> SA Game</vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_sagame'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_sagame"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_sagame"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> Sexy Game</vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_sexygame'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_sexygame"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_sexygame"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> JOKER SLOT </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_joker'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_joker"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_joker"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> PG SLOT </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_pg'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_pg"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_pg"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> KM GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_kmgame'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_kmgame"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_kmgame"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> WM GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_wmgame'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_wmgame"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_wmgame"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> AMB POKER </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_amb'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_amb"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_amb"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>SBOBET </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_sbo'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_sbo"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_sbo"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>GSS SLOT </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_gss'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_gss"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_gss"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>SimplePlay SLOT </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_spp'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_spp"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_spp"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>EVO GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_evo'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_evo"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_evo"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>DG GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_dg'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_dg"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_dg"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>MG GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_mg'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_mg"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_mg"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>PP GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_pp'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_pp"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_pp"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>RICH88 GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_rch'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_rch"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_rch"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>DRAGON GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_dragon'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_dragon"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_dragon"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>ALLBET GAME </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_allbet'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_allbet"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_allbet"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>HOTGRAPH </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_hg'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_hg"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_hg"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>ASIA GAMING </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_ag'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_ag"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_ag"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>XIN SLOT </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_xin'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_xin"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_xin"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>HUAY DRAGON </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_hdg'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_hdg"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_hdg"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>TF GAMING </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_tfg'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_tfg"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_tfg"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>SPADE GAMING </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_spg'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_spg"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_spg"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>DRAGOONSOFT GAMING </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_dgs'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_dgs"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_dgs"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>UNITED GAMING </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_ug'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_ug"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_ug"])
                  }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th class="text-success"> รวม </vs-th>
                  <vs-td
                    class="text-success"
                    v-if="table_data_all[index]['sum_all'] > 0"
                  >
                    {{ currency(table_data_all[index]["sum_all"]) }}</vs-td
                  >
                  <vs-td class="text-danger" v-else>{{
                    currency(table_data_all[index]["sum_all"])
                  }}</vs-td>
                </vs-tr>
              </vs-table>
            </vs-popup>
          </div>
        </vx-card>
        <vx-card v-else>
          <h1>ไม่พบมีข้อมูล</h1>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import moment from 'moment'
export default {
  components: {
    StatisticsCardLine,
    vSelect
  },
  data () {
    return {
      popupgame: false,
      monthOptions: [
        {
          text: 'มกราคม',
          value: '01'
        },
        {
          text: 'กุมภาพันธ์',
          value: '02'
        },
        {
          text: 'มีนาคม',
          value: '03'
        },
        {
          text: 'เมษายน',
          value: '04'
        },
        {
          text: 'พฤษภาคม',
          value: '05'
        },
        {
          text: 'มิถุนายน',
          value: '06'
        },
        {
          text: 'กรกฎาคม',
          value: '07'
        },
        {
          text: 'สิงหาคม',
          value: '08'
        },
        {
          text: 'กันยายน',
          value: '09'
        },
        {
          text: 'ตุลาคม',
          value: '10'
        },
        {
          text: 'พฤศจิกายน',
          value: '11'
        },
        {
          text: 'ธันวาคม',
          value: '12'
        }
      ],
      yearOptions: [
        {
          text: moment
            .tz('Asia/Bangkok')
            .subtract(1, 'year')
            .format('YYYY')
            .toString(),
          value: moment.tz('Asia/Bangkok').subtract(1, 'year').format('YYYY')
        },
        {
          text: moment.tz('Asia/Bangkok').format('YYYY'),
          value: moment.tz('Asia/Bangkok').format('YYYY')
        }
      ],
      month: moment.tz('Asia/Bangkok').format('MM'),
      year: moment.tz('Asia/Bangkok').format('YYYY'),
      table_data_all: [],
      table_data: [],
      sum_data: [],
      sum_deposit: 0,
      sum_withdraw: 0,
      sumsagame: 0,
      sumpgslot: 0,
      sumjokerslot: 0,
      sumsbobet: 0,
      sumgssslot: 0,
      sumsppslot: 0,
      sumppslot: 0,
      sumsexy: 0,
      sum_profit: 0,
      sumkmgame: 0,
      sumwmgame: 0,
      sumambgame: 0,
      sumevogame: 0,
      sumdggame: 0,
      summggame: 0,
      sumrchgame: 0,
      sumallbetgame: 0,
      sumdragongame: 0,
      sumhggame: 0,
      sumaggame: 0,
      sumxingame: 0,
      sumhdggame: 0,
      sumtfggame: 0,
      sumspggame: 0,
      sumdgsgame: 0,
      sumuggame: 0,
      lastupdate: moment.tz('Asia/Bangkok').format('YYYY-MM-DD H:mm:ss'),
      index: 0,
      webOptions: [
        {
          text: 'Sagame1688',
          value: '0',
          subStatus:false
        },
        {
          text: 'London1688',
          value: '1',
          subStatus:false
        },
        {
          text: 'SlotVvip88',
          value: '2',
          subStatus:false
        },
        {
          text: 'Ufabomb',
          value: '3',
          subStatus:false
        },
        {
          text: 'lalika168',
          value: '4',
          sub: '0',
          subStatus:true
        },
        {
          text: 'All',
          value: '5',
          subStatus:false
        }
      ],
      web: '0',
      nameArr : [
        process.env.VUE_APP_API_URL_SA,
        process.env.VUE_APP_API_URL_LONDON,
        process.env.VUE_APP_API_URL_VVIP,
        process.env.VUE_APP_API_URL_BOMB,
        process.env.VUE_APP_API_URL_LALIKABET
      ],
      subUrlArr : [process.env.VUE_APP_NAME_LALIKABET_168]
    }
  },
  async mounted () {
    await this.getSelectDateTimeOnlyOne(this.web, this.month, this.year)
  },
  methods: {
    combineSumAll (sumAll) {
      const combined = {}

      sumAll.forEach(arr => {
        arr.forEach(obj => {
          if (!combined.hasOwnProperty(obj.date)) {
            combined[obj.date] = {
              sumdeposit: 0,
              sumwithdraw: 0,
              sumprofit: 0,
              sum_sagame: 0,
              sum_hdg: 0,
              sum_sexygame: 0,
              sum_joker: 0,
              sum_pg: 0,
              sum_kmgame: 0,
              sum_wmgame: 0,
              sum_amb: 0,
              sum_sbo: 0,
              sum_gss: 0,
              sum_spp: 0,
              sum_evo: 0,
              sum_dg: 0,
              sum_mg: 0,
              sum_pp: 0,
              sum_rch: 0,
              sum_allbet: 0,
              sum_dragon: 0,
              sum_hg: 0,
              sum_ag: 0,
              sum_xin: 0,
              sum_tfg: 0,
              sum_spg: 0,
              sum_dgs: 0,
              sum_ug: 0,
              sum_mac: 0,
              sum_ibc: 0,
              sum_jl: 0,
              sum_gcp: 0,
              sum_qt: 0,
              sum_ob: 0,
              sum_gc: 0,
              sum_all: 0
            }
          }

          combined[obj.date].sumdeposit += parseFloat(obj.sumdeposit)
          combined[obj.date].sumwithdraw += parseFloat(obj.sumwithdraw)
          combined[obj.date].sumprofit += parseFloat(obj.sumprofit)
          combined[obj.date].sum_sagame += parseFloat(obj.sum_sagame)
          combined[obj.date].sum_hdg += parseFloat(obj.sum_hdg)
          combined[obj.date].sum_sexygame += parseFloat(obj.sum_sexygame)
          combined[obj.date].sum_joker += parseFloat(obj.sum_joker)
          combined[obj.date].sum_pg += parseFloat(obj.sum_pg)
          combined[obj.date].sum_kmgame += parseFloat(obj.sum_kmgame)
          combined[obj.date].sum_wmgame += parseFloat(obj.sum_wmgame)
          combined[obj.date].sum_amb += parseFloat(obj.sum_amb)
          combined[obj.date].sum_sbo += parseFloat(obj.sum_sbo)
          combined[obj.date].sum_gss += parseFloat(obj.sum_gss)
          combined[obj.date].sum_spp += parseFloat(obj.sum_spp)
          combined[obj.date].sum_evo += parseFloat(obj.sum_evo)
          combined[obj.date].sum_dg += parseFloat(obj.sum_dg)
          combined[obj.date].sum_mg += parseFloat(obj.sum_mg)
          combined[obj.date].sum_pp += parseFloat(obj.sum_pp)
          combined[obj.date].sum_rch += parseFloat(obj.sum_rch)
          combined[obj.date].sum_allbet += parseFloat(obj.sum_allbet)
          combined[obj.date].sum_dragon += parseFloat(obj.sum_dragon)
          combined[obj.date].sum_hg += parseFloat(obj.sum_hg)
          combined[obj.date].sum_ag += parseFloat(obj.sum_ag)
          combined[obj.date].sum_xin += parseFloat(obj.sum_xin)
          combined[obj.date].sum_tfg += parseFloat(obj.sum_tfg)
          combined[obj.date].sum_spg += parseFloat(obj.sum_spg)
          combined[obj.date].sum_dgs += parseFloat(obj.sum_dgs)
          combined[obj.date].sum_ug += parseFloat(obj.sum_ug)
          combined[obj.date].sum_mac += parseFloat(obj.sum_mac)
          combined[obj.date].sum_ibc += parseFloat(obj.sum_ibc)
          combined[obj.date].sum_jl += parseFloat(obj.sum_jl)
          combined[obj.date].sum_gcp += parseFloat(obj.sum_gcp)
          combined[obj.date].sum_qt += parseFloat(obj.sum_qt)
          combined[obj.date].sum_ob += parseFloat(obj.sum_ob)
          combined[obj.date].sum_gc += parseFloat(obj.sum_gc)
          combined[obj.date].sum_all += parseFloat(obj.sum_all)
        })
      })

      const result = {}

      let i = 0

      for (const row of Object.entries(combined)) {
        const [key, value] = row

        const newRow = value
        newRow.date = key

        result[i] = newRow
        i++
      }

      return result
    },
    async winlosegame (data) {
      this.popupgame = true
      this.index = data
    },
    setZero () {
      this.table_data_all = ''
      this.sum_deposit = '0'
      this.sum_withdraw = '0'
      this.sum_profit = '0'
      this.sumpgslot = '0'
      this.sumjokerslot = 0
      this.sumsagame = 0
      this.sumsexy = 0
      this.sumkmgame = 0
      this.sumwmgame = 0
      this.sumambgame = 0
      this.sumsbobet = 0
      this.sumgssslot = 0
      this.sumsppslot = 0
      this.sumppslot = 0
      this.sumevogame = 0
      this.sumdggame = 0
      this.summggame = 0
      this.sumrchgame = 0
      this.sumallbetgame = 0
      this.sumdragongame = 0
      this.sumhggame = 0
      this.sumaggame = 0
      this.sumxingame = 0
      this.sumhdggame = 0
      this.sumtfggame = 0
      this.sumspggame = 0
      this.sumdgsgame = 0
      this.sumuggame = 0
    },
    selectWeb (numberWeb, month, year) {
      this.setZero()
      this.selectdate_time(numberWeb, month, year)
    },
    async selectdate_time (numberWeb, month, year) {
      if (
        month === moment.tz('Asia/Bangkok').format('MM') &&
        year === moment.tz('Asia/Bangkok').format('YYYY')
      ) {

        if (this.nameArr[numberWeb]) {
          await this.getSelectDateTimeOnlyOne(numberWeb, month, year)
          await this.getRealTimeDataOnly(numberWeb)

        } else {
          await this.getSelectDateTimeAll(numberWeb, month, year)
          await this.getRealTimeDataAll(numberWeb)

        }

        // await this.get_data(numberWeb, month, year)

      } else if (this.nameArr[numberWeb]) {
        this.getSelectDateTimeOnlyOne(numberWeb, month, year)

      } else {
        this.getSelectDateTimeAll(numberWeb, month, year)

      }
    },
    async getSelectDateTimeOnlyOne (numberWeb, month, year) {

      await axios
        .get(
          await this.genUrl(numberWeb, `/dashboardmonth/selectmonth/${month}/${year}`),
          {}
        )
        .then((response) => (this.table_data = response.data))
      if (this.table_data.length === 0) {
        this.setZero()
      } else {
        this.table_data_all = this.table_data[0].sm_all
        this.sum_deposit = this.table_data[0].sm_sum_deposit
        this.sum_withdraw = this.table_data[0].sm_sum_withdraw
        this.sum_profit = this.table_data[0].sm_sum_profit

        if (this.webOptions[this.web].subStatus === true) {
          this.sumsagame =  this.currency(this.table_data[0].sm_sum_sa)
          this.sumsexy = this.currency(this.table_data[0].sm_sum_sexy)
          this.sumkmgame =  this.currency(this.table_data[0].sm_sum_km)
          this.sumdragongame =  this.currency(this.table_data[0].sm_sum_drg)
          this.sumrchgame = this.currency(this.table_data[0].sm_sum_r88)
          // sm_sum_hdg
        } else {
          this.sumsagame = this.currency(this.table_data[0].sm_sum_sagame)
          this.sumsexy = this.currency(this.table_data[0].sm_sum_sexygame)
          this.sumkmgame = this.currency(this.table_data[0].sm_sum_kingmaker)
          this.sumdragongame = this.currency(this.table_data[0].sm_sum_dragon)
          this.sumrchgame = this.currency(this.table_data[0].sm_sum_rch)
        }
        this.sumlalika = this.currency(this.table_data[0].sm_sum_llk)
        this.sumpgslot = this.currency(this.table_data[0].sm_sum_pg)
        this.sumjokerslot = this.currency(this.table_data[0].sm_sum_joker)

        this.sumgssslot = this.currency(this.table_data[0].sm_sum_gss)
        this.sumevogame = this.currency(this.table_data[0].sm_sum_evo)
        this.sumallbetgame = this.currency(this.table_data[0].sm_sum_allbet)

        this.sum_deposit = this.currency(this.table_data[0].sm_sum_deposit)
        this.sum_withdraw = this.currency(this.table_data[0].sm_sum_withdraw)
        this.sum_profit = this.currency(this.table_data[0].sm_sum_profit)

        this.sumwmgame = this.currency(this.table_data[0].sm_sum_wm)
        this.sumambgame = this.currency(this.table_data[0].sm_sum_amb)
        this.sumsbobet = this.currency(this.table_data[0].sm_sum_sbo)
        this.sumsppslot = this.currency(this.table_data[0].sm_sum_spp)
        this.sumppslot = this.currency(this.table_data[0].sm_sum_pp)
        this.sumgssslot = this.currency(this.table_data[0].sm_sum_gss)
        this.sumevogame = this.currency(this.table_data[0].sm_sum_evo)
        this.sumdggame = this.currency(this.table_data[0].sm_sum_dg)
        this.summggame = this.currency(this.table_data[0].sm_sum_mg)

        this.sumhggame = this.currency(this.table_data[0].sm_sum_hg)
        this.sumaggame = this.currency(this.table_data[0].sm_sum_ag)
        this.sumxingame = this.currency(this.table_data[0].sm_sum_xin)
        this.sumhdggame = this.currency(this.table_data[0].sm_sum_hdg)
        this.sumtfggame = this.currency(this.table_data[0].sm_sum_tfg)
        this.sumspggame = this.currency(this.table_data[0].sm_sum_spg)
        this.sumdgsgame = this.currency(this.table_data[0].sm_sum_dgs)
        this.sumuggame = this.currency(this.table_data[0].sm_sum_ug)
      }
    },
    async getSelectDateTimeAll (numberWeb, month, year) {
      const smAll = []
      const sum = {
        withdraw: 0,
        deposit: 0,
        profit: 0,
        gameSexy: 0,
        gameSa: 0,
        gameKm: 0,
        gameWm: 0,
        gameAmb: 0,
        gameSbo: 0,
        gameGss: 0,
        gameSpp: 0,
        gamePp: 0,
        gameEvo: 0,
        gameDg: 0,
        gameMg: 0,
        gameRch: 0,
        gameAllbet: 0,
        gameDragon: 0,
        gameHg: 0,
        gameAg: 0,
        gameXing: 0,
        gameHdg: 0,
        gameTfg: 0,
        gameSpg: 0,
        gameDgs: 0,
        gameUg: 0,
        gameJoker:0,
        gamePg:0
      }
      for (let i = 0; i < numberWeb; i++) {

        await axios
          .get(
            await this.genUrl(i, `/dashboardmonth/selectmonth/${month}/${year}`),
            {}
          )
          .then((response) => (this.table_data = response.data))


        if (this.webOptions[i].subStatus === true) {
          sum.gameSexy =
          parseFloat(sum.gameSexy) +
          parseFloat(this.table_data[0].sm_sum_sexy)
          sum.gameSa =
          parseFloat(sum.gameSa) + parseFloat(this.table_data[0].sm_sum_sa)
          sum.gameKm =
          parseFloat(sum.gameKm) +
          parseFloat(this.table_data[0].sm_sum_km)
          sum.gameRch =
          parseFloat(sum.gameRch) + parseFloat(this.table_data[0].sm_sum_r88)
        } else {
          sum.gameSexy =
          parseFloat(sum.gameSexy) +
          parseFloat(this.table_data[0].sm_sum_sexygame)
          sum.gameSa =
          parseFloat(sum.gameSa) + parseFloat(this.table_data[0].sm_sum_sagame)
          sum.gameKm =
          parseFloat(sum.gameKm) +
          parseFloat(this.table_data[0].sm_sum_kingmaker)
          sum.gameRch =
          parseFloat(sum.gameRch) + parseFloat(this.table_data[0].sm_sum_rch)
        }

        sum.withdraw =
          parseFloat(sum.withdraw) +
          parseFloat(this.table_data[0].sm_sum_withdraw)
        sum.deposit =
          parseFloat(sum.deposit) +
          parseFloat(this.table_data[0].sm_sum_deposit)
        sum.profit =
          parseFloat(sum.profit) + parseFloat(this.table_data[0].sm_sum_profit)


        sum.gameWm =
          parseFloat(sum.gameWm) + parseFloat(this.table_data[0].sm_sum_wm)
        sum.gamePg =
          parseFloat(sum.gamePg) + parseFloat(this.table_data[0].sm_sum_pg)
        sum.gameJoker =
          parseFloat(sum.gameJoker) + parseFloat(this.table_data[0].sm_sum_joker)
        sum.gameAmb =
          parseFloat(sum.gameAmb) + parseFloat(this.table_data[0].sm_sum_amb)
        sum.gameSbo =
          parseFloat(sum.gameSbo) + parseFloat(this.table_data[0].sm_sum_sbo)
        sum.gameGss =
          parseFloat(sum.gameGss) + parseFloat(this.table_data[0].sm_sum_gss)
        sum.gameSpp =
          parseFloat(sum.gameSpp) + parseFloat(this.table_data[0].sm_sum_spp)
        sum.gamePp =
          parseFloat(sum.gamePp) + parseFloat(this.table_data[0].sm_sum_pp)
        sum.gameEvo =
          parseFloat(sum.gameEvo) + parseFloat(this.table_data[0].sm_sum_evo)
        sum.gameDg =
          parseFloat(sum.gameDg) + parseFloat(this.table_data[0].sm_sum_dg)

        if (this.table_data[0].sm_sum_mg) {
          sum.gameMg =
          parseFloat(sum.gameMg) + parseFloat(this.table_data[0].sm_sum_mg)
        }

        sum.gameHg =
          parseFloat(sum.gameHg) + parseFloat(this.table_data[0].sm_sum_hg)
        sum.gameAg =
          parseFloat(sum.gameAg) + parseFloat(this.table_data[0].sm_sum_ag)
        sum.gameXing =
          parseFloat(sum.gameXing) + parseFloat(this.table_data[0].sm_sum_xin)
        sum.gameHdg =
          parseFloat(sum.gameHdg) + parseFloat(this.table_data[0].sm_sum_hdg)
        sum.gameTfg =
          parseFloat(sum.gameTfg) + parseFloat(this.table_data[0].sm_sum_tfg)
        sum.gameSpg =
          parseFloat(sum.gameSpg) + parseFloat(this.table_data[0].sm_sum_spg)
        sum.gameUg =
          parseFloat(sum.gameUg) + parseFloat(this.table_data[0].sm_sum_ug)

        smAll.push(this.table_data[0].sm_all)
      }

      const sumAllCombined = Object.values(this.combineSumAll(smAll))
      const arrAns = []
      arrAns.push(sumAllCombined)
      // if (this.table_data.length === 0) {
      //   this.setZero()
      // } else {
      this.table_data_all = sumAllCombined
      this.sum_deposit = this.currency(sum.deposit)
      this.sum_withdraw = this.currency(sum.withdraw)
      this.sum_profit = this.currency(sum.profit)
      this.sumsexy = this.currency(sum.gameSexy)
      this.sumsagame = this.currency(sum.gameSa)
      this.sumkmgame = this.currency(sum.gameKm)
      this.sumwmgame = this.currency(sum.gameWm)
      this.sumambgame = this.currency(sum.gameAmb)
      this.sumsbobet = this.currency(sum.gameSbo)
      this.sumgssslot = this.currency(sum.gameGss)
      this.sumsppslot = this.currency(sum.gameSpp)
      this.sumppslot = this.currency(sum.gamePp)
      this.sumevogame = this.currency(sum.gameEvo)
      this.sumdggame = this.currency(sum.gameDg)
      this.summggame = this.currency(sum.gameMg)
      this.sumrchgame = this.currency(sum.gameRch)
      this.sumallbetgame = this.currency(sum.gameAllbet)
      this.sumdragongame = this.currency(sum.gameDragon)
      this.sumhggame = this.currency(sum.gameHg)
      this.sumaggame = this.currency(sum.gameAg)
      this.sumxingame = this.currency(sum.gameXing)
      this.sumhdggame = this.currency(sum.gameHdg)
      this.sumtfggame = this.currency(sum.gameTfg)
      this.sumspggame = this.currency(sum.gameSpg)
      this.sumdgsgame = this.currency(sum.gameDgs)
      this.sumuggame = this.currency(sum.gameUg)
      this.sumjokerslot = this.currency(sum.gameJoker)
      this.sumpgslot = this.currency(sum.gamePg)
      // }
    },
    async getRealTimeDataOnly (numberWeb) {
      if (this.webOptions[numberWeb].subStatus === true) {
        this.sum_deposit =  this.sum_deposit
        this.sum_withdraw = this.sum_withdraw
        this.sum_profit = this.sum_profit
      } else {
        await axios
          .get(await this.genUrl(numberWeb, '/dashboardmonth/deposit'))
          .then((response) => (this.sum_data = response.data))
        this.sum_deposit = this.currency(this.sum_data.sumdeposit)
        this.sum_withdraw = this.currency(this.sum_data.sumwithdraw)
        this.sum_profit = this.currency(this.sum_data.profit)

      }
    },
    async getRealTimeDataAll (numberWeb) {
      const r = {
        withdraw: 0,
        deposit: 0,
        profit: 0
      }


      for (let i = 0; i < numberWeb; i++) {
        if (this.webOptions[i].subStatus === false) {
          await axios
            .get(await  this.genUrl(i, '/dashboardmonth/deposit', `/dashboardmonth/selectmonth/${this.month}/${this.year}`))
            .then((response) => (this.sum_data = response.data))
          r.withdraw =
          parseFloat(r.withdraw) +
          parseFloat(this.sum_data.sumwithdraw)
          r.deposit =
          parseFloat(r.deposit) +
          parseFloat(this.sum_data.sumdeposit)
          r.profit =
          parseFloat(r.profit) + parseFloat(this.sum_data.profit)
        } else {
          await axios
            .get(await  this.genUrl(i, '/dashboardmonth/deposit', `/dashboardmonth/selectmonth/${this.month}/${this.year}`))
            .then((response) => (this.sum_data = response.data))
          r.withdraw =
          parseFloat(r.withdraw) +
          parseFloat(this.sum_data[0].sm_sum_withdraw)
          r.deposit =
          parseFloat(r.deposit) +
          parseFloat(this.sum_data[0].sm_sum_deposit)
          r.profit =
          parseFloat(r.profit) + parseFloat(this.sum_data[0].sm_sum_profit)
        }

      }
      this.sum_deposit = this.currency(r.deposit)
      this.sum_withdraw = this.currency(r.withdraw)
      this.sum_profit = this.currency(r.profit)


    },
    currency (amount) {
      amount = (amount * 1.0).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
        // amount = String(Math.abs(amount))
        // return `-${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
    },
    genUrl (valueNumberWeb, fixPathNoemal, fixPathCreate) {
      if (this.webOptions[valueNumberWeb].subStatus === true) {
        if (fixPathCreate) {
          return `${this.nameArr[valueNumberWeb]}${fixPathCreate}/${this.subUrlArr[this.webOptions[valueNumberWeb].sub]}`
        } else {
          return `${this.nameArr[valueNumberWeb]}${fixPathNoemal}/${this.subUrlArr[this.webOptions[valueNumberWeb].sub]}`
        }

      } else {
        return `${this.nameArr[valueNumberWeb]}${fixPathNoemal}`
      }
    }
  }
}
</script>
